import React from 'react';
// import logo from './logo.svg';
// import './App.css';
// import Container from '@material-ui/core/Container';
import Main from './Main';

const App = () => {
  // return (
  //   <Container >
  //     <Main />
  //   </Container>
  // );
  return (
    <Main />
  );
}

export default App;
